<template>
    <div class="align-items-center space-x-4 w-100">
        <input v-model="checked" :value="data.checked" @change="checkboxCategory($event, data)" type="checkbox" class="font-xs checkbox-height" >
        <span class="text-gray-900">{{ data.name }}</span>
        <span v-if="checked=== true" v-on:click="deleteCategory(data)" class="text-gray-900 float-right mr-1 "><i class="el-icon-delete-solid text-danger"></i></span>
        <span v-if="checked=== true" v-on:click="openCategoryModal(data)" class="text-gray-900 float-right"><i class="el-icon-edit text-info"></i></span>

    </div>
</template>

<script>
export default {
    name : 'BlogCategory',
    props : {
        data : {
            type : Object,
            require : true,
        }
    },
    data () {
        return {
            checked : false,
        }
    },
    methods : {
        checkboxCategory (e) {
            this.$store.commit('portal/TOGGLE_BLOG_CATEGORY', { value : this.checked, category : this.data })
        },
       deleteCategory (data) {
                this.$confirm(`${this.$t('removeBlogCategory')} ${data.name}?`, `${this.$t('warning')}`, {
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('cancel'),
                    type: 'warning',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true
                            instance.confirmButtonText = 'Loading...'
                             this.$store.dispatch('portal/deleteBlogCategory', { id : data.id }).then(_ => {
                               instance.confirmButtonLoading = false
                               done()
                               window.Bus.$emit('delete-blog-category')
                               }).catch(error => {
                                // done()
                                instance.confirmButtonLoading = false
                                if (error.response.status === 400 || error.response.status === 500 || error.response.status === 403) {
                                    this.$services.helpers.notification(this.$t('serverError'), 'error', this)
                                }
                            })
                        } else {
                            done()
                            instance.confirmButtonLoading = false
                        }
                    }
                }).then(() => {
                })
                .catch(() => {

                })
            },
       openCategoryModal (data) {
               window.Bus.$emit('open-edit-blog-category', data)
            },
    }
}
</script>

<style>
.relative-pos-right {
    position: relative;
    right: -91px;
}
</style>
