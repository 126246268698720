<template>
	<div class="app-main__inner">
		<div class="container mb-3 dm-sans-font">
			<div class="row flex flex-col md:flex-row justify-between align-items-center">
				<div>
					<div>
						<div class="flex justify-between align-items-center space-x-3 mb-3 cursor-pointer ml-3">
							<span data-cy='article-heading'
								  class="text-sm md:text-2xl font-semibold text-gray-900 dashboard-head-size">{{
									$t('createBlog')
								}}</span>
						</div>
					</div>
				</div>
			</div>

			<div class="row no-gutters space-y-4 lg:space-y-0">
				<div class="col-md-12 col-lg-9">
					<div class="row bg-white border rounded-md lg:mr-5 p-3 justify-between">
						<div class="col-md-12">
							<div class="form-group">
								<label class="font-semibold" for="blog title">{{ $t('blogTitle') }}</label>
								<input v-model="title" type="text"
									   class="flex justify-between align-items-center h-10 space-x-4 focus:outline-none border px-2 py-1 rounded-md text-gray-500 w-full">
							</div>
						</div>
						<!--						<div class="col-md-6">-->
						<!--							<div class="form-group position-relative">-->
						<!--								<label class="font-semibold w-full"-->
						<!--									   v-on:click="openLinkedProduct">{{ $t('linkedArticle') }} <span-->
						<!--									class="help-tooltip cursor-pointer float-right"-->
						<!--									v-bind:title="$t('linkedArticleHelpText')"></span></label>-->
						<!--								<span class="el-icon-search article-icon"></span>-->
						<!--								<el-select-->

						<!--									class="w-full article-select"-->
						<!--									v-model="articleNumbers"-->
						<!--									multiple-->
						<!--									filterable-->
						<!--									remote-->
						<!--									:collapse-tags="true"-->
						<!--									:multiple-limit="5"-->
						<!--									suffix-icon="el-icon-search"-->
						<!--									:remote-method="filterArticles"-->
						<!--									default-first-option-->
						<!--									placeholder="">-->
						<!--									<el-option-->
						<!--										v-for="item in GET_ARTICLES.data"-->
						<!--										:key="item.id"-->
						<!--										:label="item.description"-->
						<!--										:value="item.id">-->
						<!--									</el-option>-->
						<!--								</el-select>-->
						<!--							</div>-->
						<!--						</div>-->
						<div class="col-md-12">
							<div class="form-group mt-2">
								<label class="font-semibold" for="blog title">{{ $t('blogContent') }}</label>
								<vue-editor class="h-custom-screen" v-model="content"
											:editorToolbar="customToolbar"></vue-editor>
							</div>
						</div>
						<div class="col-md-12">
							<div class="grid grid-cols-1 gap-1 lg:grid-cols-2 lg:gap-2">
								<div>
									<label
										class="label-w-input col-form-label font-light whitespace-nowrap  mb-1 ">
										<input data-cy='apply-is_newsletter'
											   type="checkbox"
											   id="is_pin_as_top_post"
											   v-model="is_pin_as_top_post"
											   class="checkbox-height  h-4 w-4">
										<span class="ml-3">{{ $t('pinAsTopPost') }}<span
											class="help-tooltip cursor-pointer"
											v-bind:title="$t('pinAsTopPost')"></span></span>
									</label>
									<div class="mt-1 flex" v-if="is_pin_as_top_post === true">
										<el-color-picker v-model="banner_color"></el-color-picker>
										<label
											class="flex align-items-center col-form-label font-light whitespace-nowrap  ml-2">{{
												$t('bannerBackgroundColor')
											}}<span class="help-tooltip cursor-pointer"
													:title="$t('bannerBackgroundColor')"></span></label>
									</div>
								</div>
								<div>
									<label
										class="label-w-input col-form-label font-light whitespace-nowrap  mb-1 ">
										<input data-cy='apply-is_newsletter'
											   type="checkbox"
											   id="is_newsletter"
											   v-model="is_newsletter"
											   class="checkbox-height  h-4 w-4">
										<span class="ml-3">{{ $t('sendAsNewsLetter') }}<span
											class="help-tooltip cursor-pointer"
											v-bind:title="$t('sendAsNewsLetter')"></span></span>
									</label>
									<div class="form-group ml-3 row" v-if="is_newsletter">
										<span class="mb-2 col-6">{{ $t('newsletterDate') }}</span>
										<span class="col-6">
											<el-date-picker
												size="mini"

												v-model="newsletterDate"
												type="date"
												format="yyyy-MM-dd"
												value-format="yyyy-MM-dd">
										</el-date-picker>
										</span>

									</div>
								</div>

							</div>

							<!--							<div class="row">-->
							<!--								<div class="col-md-7">-->
							<!--									<div class="form-group">-->

							<!--									</div>-->
							<!--									<div>-->

							<!--									</div>-->
							<!--								</div>-->

							<!--							</div>-->
						</div>
						<div class="col-md-12">
							<div class="w-full border-bottom mt-2" v-on:click="onLinkedProduct">
								<i class="lnr-chevron-down font-semibold ml-1 icon-size"
								   v-if="linkedProductToggle === false"></i>
								<i class="lnr-chevron-up font-semibold ml-1 icon-size" v-else></i><span
								class="ml-2 font-semibold text-base">{{ $t('linkedArticle') }}</span></div>
							<div class="form-group unset-margin mt-2" v-if="linkedProductToggle === true">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs">{{
										$t('linkedArticleField')
									}}</label>
								<div>
									<input v-model="articleSearchName" type="text" @input="filterArticle"
										   @focus="openCustomersDropdown = true"
										   :placeholder="$t('searchArticles')"
										   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
								</div>
								<div class="absolute inset-0 z-40" @click="openArticleDropdown = false"
									 v-if="openArticleDropdown"></div>
								<div style='z-index:99999'
									 class="w-1/2 absolute bg-white shadow border rounded mt-1"
									 v-if="openArticleDropdown && filteredArticle.length > 0">
									<ul>
										<li class="py-1 pl-2 hover:bg-gray-100 cursor-pointer"
											v-for="item in filteredArticle.slice(0, 7)" :key="item.id"
											@click="addArticleToList(item)">{{ item.description }}
										</li>
									</ul>
								</div>
							</div>
							<div class="row" v-if="linkedProductToggle === true">
								<div class="col-md-6 mb-1 mt-2" v-for="(article,key) in selectedArticle"
									 :key="article.id">
									<div class="flex justify-between bg-white border rounded-md shadow-sm p-1">
										<div>{{ article.description }}</div>
										<div>
                                                <span class="cursor-pointer"
													  @click="removeUserFromSelectedList(key)">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
														 class="h-5 w-5 bg-red-400 text-white border-2 rounded-full p-1"
														 viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd"
														  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
														  clip-rule="evenodd"/>
                                                    </svg>
                                                </span>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-md-12">
							<div class="form-group mt-5">
								<el-upload
									class="upload-demo"
									action=""
									ref="upload"
									:on-preview="handlePreview"
									:on-remove="handleRemove"
									:before-remove="beforeRemove"
									:on-change="handleChange"
									multiple
									:limit="1"
									:auto-upload="false"
									:on-exceed="handleExceed"
									:file-list="fileList">
									<el-button size="small" type="primary">{{ $t('uploadBlogImage') }}</el-button>
									<div slot="tip" class="el-upload__tip">{{ $t('maxImageUploadMessage') }}</div>
								</el-upload>
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="col-lg-1"></div> -->
				<div class="col-md-12 col-lg-3">
					<div
						class="flex flex-col h-full justify-between bg-white border rounded-md p-3 justify-between space-y-4">
						<div class="flex flex-col space-y-3">
							<span class="font-semibold text-lg text-gray-900">{{ $t('publish') }}</span>
							<div class="flex flex-col space-y-3">
								<div class="flex justify-between" v-if="false">
									<span>{{ $t('createdDate') }}</span>
									<span>{{ $services.moment().format('L') }}</span>
								</div>
								<div class="row no-gutters">
									<span class="col-4" style="word-break: break-word;">{{
											$t('publishDate')
										}}</span>
									<span class="col-8">
                                        <div class="blog-date-publish">
                                            <el-date-picker
												size="mini"
												v-model="datePublish"
												type="date"
												format="yyyy-MM-dd"
												value-format="yyyy-MM-dd">
                                            </el-date-picker>
                                        </div>
                                    </span>
								</div>
							</div>
							<div class="flex flex-col space-y-3">
								<div class="flex justify-between" v-if="false">
									<span>{{ $t('createdDate') }}</span>
									<span>{{ $services.moment().format('L') }}</span>
								</div>
								<!--								<div class="flex">-->
								<div class="row no-gutters">
										<span class="col-4" style="word-break: break-word;">{{
												$t('publishEndDate')
											}}</span>
									<span class="col-8">
                                        <div class="blog-date-publish">
                                            <el-date-picker
												size="mini"
												v-model="dateEndPublish"
												type="date"
												format="yyyy-MM-dd"
												value-format="yyyy-MM-dd">
                                            </el-date-picker>
                                        </div>
										<!-- <input type="text" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-2 py-1 rounded-md text-gray-500 w-4/5"> -->
                                    </span>
								</div>

								<!--								</div>-->
								<!--                                  <div class="flex">-->
								<!--                                    <span class="w-1/2">Visibility</span>-->
								<!--                                    <span class="flex justify-end" style="width: 50%;">-->
								<!--                                        <select v-model="visibility" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-1 rounded-md text-gray-500 w-full">-->
								<!--                                            <option v-bind:key="key" :value="item.key"  v-for="(item,key) in [{key: 'Published',name:'Published'},{key: 'Draft',name:'Draft'}]">{{ item.name }}</option>-->
								<!--                                        </select>-->
								<!--                                    </span>-->
								<!--                                </div>-->
							</div>
							<div class="flex justify-end">
								<button
									class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md bg-yellow-500 text-white text-center"
									v-on:click="onPreview">{{ $t('preview') }}
								</button>
								<button v-if="is_newsletter === true" @click="onEmailPreview"
										class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md bg-green-500 text-white text-center">
									{{ $t('previewEmail') }}
								</button>
							</div>
						</div>
						<div class="flex flex-col space-y-5 border p-3 rounded-md">
							<span class="font-semibold text-lg text-gray-900">{{ $t('category') }}</span>
							<div class="flex flex-col">
								<ul class="space-y-2 overflow-y-scroll h-300">
									<li class="flex align-items-center space-x-4" v-for="category in GET_BLOG_CATEGORY"
										:key="category.id">
										<BlogCategory :data="category"/>
									</li>
								</ul>
							</div>
							<div @click="openAddModalCategory" class="flex font-semibold text-blue-800 cursor-pointer">
								+ {{ $t('addNewCategory') }}
							</div>
						</div>
						<div class="flex flex-col">
							<span class="font-semibold text-lg text-gray-900">Tags</span>
							<div class="form-group flex flex-col">
								<el-select
									v-model="value"
									multiple
									filterable
									allow-create
									default-first-option
									placeholder="">
									<el-option
										v-for="item in tagOptions"
										:key="item.id"
										:label="item.name"
										:value="item.name">
									</el-option>
								</el-select>
								<!-- <input type="text" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-2 py-1 rounded-md text-gray-500 w-full"> -->
								<!-- <span class="flex font-semibold text-blue-800">
									save Tags
								</span> -->
							</div>
							<div class="flex justify-between mt-3">
								<button :disabled='publishing || processing' @click="createBlogPost('Published')"
										class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md bg-green-500 text-white w-2/5 text-center">
<!--									<span v-if="publishing">{{ $t('publishing') }} ...</span>-->
									<span v-if="publishing"
										  class="flex align-items-center space-x-3">
                                                <svg class="animate-spin mr-2 h-5 w-5 text-white"
													 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
														stroke-width="4"></circle>
                                                <path class="opacity-75" fill="currentColor"
													  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </span>
									<span v-else>{{ $t('publish') }}</span>
								</button>

								<button :disabled='publishing || processing' @click="createBlogPost('Draft')"
										class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md bg-blue-800 text-white w-2/5 text-center">
									<span v-if="processing"
										  class="flex align-items-center space-x-3">
                                                <svg class="animate-spin mr-2 h-5 w-5 text-white"
													 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
														stroke-width="4"></circle>
                                                <path class="opacity-75" fill="currentColor"
													  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </span>
									<span v-else>{{ $t('save') }}</span>
								</button>
							</div>
							<div class="flex justify-end">
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
		<!-- <Notification /> -->
	</div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import {DatePicker} from 'element-ui'
import {VueEditor} from 'vue2-editor'
import BlogCategory from './components/BlogCategory.vue'

import debounce from "lodash.debounce"

Vue.use(DatePicker)

export default {
	name: 'CreateBlog',
	components: {
		VueEditor,
		BlogCategory
	},
	data() {
		return {
			title: '',
			is_newsletter: false,
			is_pin_as_top_post: false,
			banner_color: '',
			linkedProductToggle: false,
			content: '',
			customToolbar: [
				['bold', 'italic', 'underline'],
				[{list: 'ordered'}, {list: 'bullet'}],
				['code-block']
			],
			datePublish: '',
			newsletterDate: '',
			dateEndPublish: '',
			visibility: 'Published',
			processing: false,
			publishing: false,
			previewStatus: false,
			blogId: '',
			tagOptions: [],
			value: [],
			articleNumbers: [],
			fileList: [],
			openArticleDropdown: false,
			articleSearchName: '',
			filteredArticle: [],
			selectedArticle: [],
		}
	},

	computed: {
		...mapGetters({
			GET_BLOG_CATEGORY: 'portal/GET_BLOG_CATEGORY',
			GET_CATEGORY_CHECKED: 'portal/GET_CATEGORY_CHECKED',
			GET_ARTICLES: 'portal/GET_ARTICLES'
		})
	},
	mounted() {
		this.getBlogCategory()
		this.getBlogTags()
		this.getArticles()
		window.Bus.$on('close-preview-blog', _ => {
			this.previewStatus = false
		})
		window.Bus.$on('delete-blog-category', _ => {
			this.getBlogCategory()
		})
	},
	methods: {

		filterArticle: debounce(function () {
			this.$store.dispatch('portal/getArticles', {url: `?limit=50&search=${this.articleSearchName}`})
				.then(res => {
					this.filteredArticle = res.data.data
					this.openArticleDropdown = true
				}).catch((err) => {
				window.Bus.$emit('output-error', err)
			})
		}, 500),
		addArticleToList: function (item) {
			let exist = this.selectedArticle.filter(item_ => parseInt(item.id) === parseInt(item_.id))
			if (exist.length < 1) {
				if (this.selectedArticle.length >= 5) {
					return
				}
				this.selectedArticle.push(item)
			}
			this.openArticleDropdown = false
		},
		removeUserFromSelectedList: function (key) {
			this.selectedArticle.splice(key, 1)
		},
		onLinkedProduct: function () {
			this.linkedProductToggle = !this.linkedProductToggle
		},
		// openLinkedProduct: function () {
		// 	window.Bus.$emit('open-linked-product', {})
		// },
		getBlogCategory() {
			this.$store.dispatch('portal/getBlogCategory')
		},
		getBlogTags() {
			this.$store.dispatch('portal/getBlogTags')
				.then(res => {
					this.tagOptions = res.data.results
				})
				.catch(err => {
					if (err.response.data === 400) {
						this.$services.helpers.notification(this.$t('errorTag'), 'error', this)
					}
				})
		},
		openAddModalCategory() {
			window.Bus.$emit('add-blog-category')
		},
		createBlogPost(status) {
			if (this.title === '' || this.content === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else if (this.fileList.length < 1) {
				this.$services.helpers.notification(this.$t('addBlogImage'), 'error', this)
			} else if (this.GET_CATEGORY_CHECKED.data.length < 1) {
				this.$services.helpers.notification(this.$t('addBlogCategory'), 'error', this)
			} else if (this.datePublish === '') {
				this.$services.helpers.notification(this.$t('addBlogDatePublish'), 'error', this)
			} else if (this.$services.moment().diff(this.datePublish, 'days') > 0) {
				this.$services.helpers.notification(this.$t('publishDatePast'), 'error', this)
			} else {
				status === 'save' ? this.processing = true : this.publishing = true
				// Check if tags is added, post them and add blog information
				const postingTags = {
					tags: [],
					existingTag: []
				}
				this.value.forEach(element => {
					// Check if tag is already created
					const filterData = this.tagOptions.filter(item => item.name === element)
					if (filterData.length > 0) {
						postingTags.existingTag.push(filterData[0])
					} else {
						postingTags.tags.push(element)
					}
				})
				if (this.datePublish !== '' && this.dateEndPublish !== '') {
					if (this.$services.moment(this.dateEndPublish).format('YYYY-MM-DD') < this.$services.moment(this.datePublish).format('YYYY-MM-DD')) {
						this.$services.helpers.notification(this.$t('publishEndDatePast'), 'error', this)
						this.processing = false
						this.publishing = false
						return false
					}
				}
				if (postingTags.tags.length > 0) { // Adding new tags before adding blog
					this.createUpdateBlogWithNewTags(status, postingTags)
				} else {
					this.createUpdateBlogWithoutNewTags(status, postingTags)
				}

			}
		},
		clearBlogPost() {
			this.title = ''
			this.content = ''
			this.datePublish = ''
			this.value = []
			this.fileList = []
			this.$refs.upload.clearFiles()
			this.GET_CATEGORY_CHECKED.data.forEach(element => {
				element.checked = false
			})
			this.$store.commit('portal/RESET_BLOG_CATEGORY', false)
			this.getBlogTags()
		},
		handleRemove(file, fileList) {
			this.fileList = []
		},
		handleChange(file, fileList) {
			if (fileList.length > 1) {
				return
			}
			const img = new Image()
			img.src = window.URL.createObjectURL(file.raw)
			img.onload = () => {
				if (img.width < 5000 || img.height < 3000) {
					this.fileList.push(file)
				} else {
					this.$services.helpers.notification(this.$t('maxImageUploadMessageError'), 'error', this)
					setTimeout(function () {
						document.getElementsByClassName('el-icon-close')[0].click()
					}, 500)
				}
			}
		},
		handlePreview(file) {
	
		},
		handleExceed(files, fileList) {
			// this.$message.warning(`The limit is 3, you selected ${files.length} files this time, add up to ${files.length + fileList.length} totally`)
		},
		beforeRemove(file, fileList) {
			// return this.$confirm(`Cancel the transfert of ${file.name} ?`)
		},
		onPreview() {
			let file = ''
			if (this.fileList.length > 0) {
				file = this.fileList[0].raw
			}
			const postingTags = []
			this.value.forEach(element => {
				// Check if tag is already created
				const filterData = this.tagOptions.filter(item => item.name === element)
				if (filterData.length > 0) {
					postingTags.push(filterData[0])
				} else {
					postingTags.push({name: element})
				}
			})
			const content = {
				title: this.title,
				content: this.content,
				file: file,
				category: this.GET_CATEGORY_CHECKED.data,
				published: this.datePublish,
				tags: postingTags,
				section: 'create',
				status: this.previewStatus
			}

			window.localStorage.setItem('__blogPreviewImageP__', ' ')
			window.localStorage.setItem('__blogAttachedProduct__', ' ')
			// this.$store.commit('portal/SET_BLOG_POST_PREVIEW', content)
			const routerName = this.$router.resolve({name: 'previewblog'})
			if (this.selectedArticle.length > 0) {
				window.localStorage.setItem('__blogAttachedProduct__', JSON.stringify(this.selectedArticle))
			}
			window.localStorage.setItem('__blogPreview__', JSON.stringify(content))
			window.localStorage.setItem('__blogPreviewImage__', this.getPreviewImage(file))
			setTimeout(window.open(routerName.href, '_blank'), 200)
		},
		onEmailPreview() {
			let file = ''
			if (this.fileList.length > 0) {
				file = this.fileList[0].raw
			}
			const postingTags = []
			this.value.forEach(element => {
				// Check if tag is already created
				const filterData = this.tagOptions.filter(item => item.name === element)
				if (filterData.length > 0) {
					postingTags.push(filterData[0])
				} else {
					postingTags.push({name: element})
				}
			})
			const content = {
				title: this.title,
				content: this.content,
				file: file,
				category: this.GET_CATEGORY_CHECKED.data,
				published: this.datePublish,
				tags: postingTags,
				section: 'create',
				status: this.previewStatus
			}

			window.localStorage.setItem('__blogPreviewImageP__', ' ')
			window.localStorage.setItem('__blogAttachedProduct__', ' ')
			// this.$store.commit('portal/SET_BLOG_POST_PREVIEW', content)
			const routerName = this.$router.resolve({name: 'email-template'})
			if (this.selectedArticle.length > 0) {
				window.localStorage.setItem('__blogAttachedProduct__', JSON.stringify(this.selectedArticle))
			} else {
				window.localStorage.setItem('__blogAttachedProduct__', JSON.stringify([]))
			}
			window.localStorage.setItem('__blogPreview__', JSON.stringify(content))
			window.localStorage.setItem('__blogPreviewImage__', this.getPreviewImage(file))
			setTimeout(window.open(routerName.href, '_blank'), 200)
		},
		openBlogDrawer() {
			let file = ''
			if (this.fileList.length < 0) {
				file = this.fileList[0].raw
			}
			const content = {
				title: this.title,
				content: this.content,
				file: file,
				category: this.GET_CATEGORY_CHECKED.data,
				status: this.previewStatus
			}
			this.$store.commit('portal/SET_BLOG_POST_PREVIEW', content)
			const routerName = this.$router.resolve({name: 'previewblog'})
			window.open(routerName.href, '_blank')
		},
		createUpdateBlogWithNewTags(status, postingTags) {
			this.$store.dispatch('portal/addBlogTags', postingTags)
				.then(res => {
					const payload = {}
					const formData = new FormData()
					const addedTag = res.data.data
					// Check if existing tag was added
					if (postingTags.existingTag.length > 0) {
						postingTags.existingTag.forEach(element => {
							addedTag.push(element)
						})
					}
					payload.tags = []
					addedTag.forEach(element => {
						payload.tags.push(element.id)
					})
					formData.append('tags', payload.tags)
					if (this.selectedArticle.length > 0) {
						let articleList = []
						this.selectedArticle.map(item => {
							articleList.push(item.id)
						})
						formData.append('recommended_products', articleList)
					}
					// Check for categories
					if (this.GET_CATEGORY_CHECKED.data.length > 0) {
						payload.categories = []
						this.GET_CATEGORY_CHECKED.data.forEach(element => {
							payload.categories.push(element.id)
						})
						formData.append('categories', payload.categories)
					}

					formData.append('date_published', this.datePublish)
					formData.append('visibility', status)
					formData.append('title', this.title)
					formData.append('content', this.content)
					formData.append('file', this.fileList[0].raw)
					let is_newsletter = this.is_newsletter === true ? 1 : 0
					let is_pin_as_top_post = this.is_pin_as_top_post === true ? 1 : 0
					formData.append('is_newsletter', is_newsletter)
					formData.append('is_pin_as_top_post', is_pin_as_top_post)
					formData.append('banner_color', this.banner_color)
					if (this.newsletterDate !== '') {
						formData.append('newsletter_send_date', this.dateEndPublish)
					}
					if (this.dateEndPublish && status !== 'Published') {
						formData.append('schedule_end_date', this.dateEndPublish)
						formData.append('visibility', 'Draft')
					}

					if (this.blogId !== '') {
						formData.append('id', this.blogId)
						this.$store.dispatch('portal/updateBlogPost', formData)
							.then(res => {
								this.processing = false
								this.publishing = false
								this.$services.helpers.notification(this.$t('blogUpdatedSuccess'), 'success', this)
							})
							.catch(err => {
								this.processing = false
								this.publishing = false
								if (err.response.data === 400) {
									this.$services.helpers.notification(err.response.data.error, 'error', this)
								}
								if (err.response.status === 401) {
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								}
							})
					} else {
						this.$store.dispatch('portal/addBlogPost', formData).then(res => {
							status === 'save' ? this.processing = false : this.publishing = false
							this.$services.helpers.notification(this.$t('blogAddedSuccess'), 'success', this)
							this.blogId = res.data.blog_post_id
							// this.clearBlogPost()
						})
							.catch(err => {
								this.processing = false
								this.publishing = false
								status === 'save' ? this.processing = false : this.publishing = false
								if (err.response.data === 400) {
									this.$services.helpers.notification(err.response.data.error, 'error', this)
								}
							})
					}
				}).catch(err => {
				this.processing = false
				this.publishing = false
				window.Bus.$emit('output-error', err)
			})
		},
		createUpdateBlogWithoutNewTags(status, postingTags) {
			// Check for existing tags
			const payload = {}
			const formData = new FormData()
			// Check if existing tag was added
			if (postingTags.existingTag.length > 0) {
				payload.tags = []
				postingTags.existingTag.forEach(element => {
					payload.tags.push(element.id)
				})
				formData.append('tags', payload.tags)
			}

			// Check for categories
			if (this.GET_CATEGORY_CHECKED.data.length > 0) {
				payload.categories = []
				this.GET_CATEGORY_CHECKED.data.forEach(element => {
					payload.categories.push(element.id)
				})
				formData.append('categories', payload.categories)
			}
			if (this.selectedArticle.length > 0) {
				let articleList = []
				this.selectedArticle.map(item => {
					articleList.push(item.id)
				})
				formData.append('recommended_products', articleList)
			}
			const visible = status === 'save' ? 'Draft' : 'Published'

			formData.append('date_published', this.datePublish)
			let is_newsletter = this.is_newsletter === true ? 1 : 0
			let is_pin_as_top_post = this.is_pin_as_top_post === true ? 1 : 0
			formData.append('is_newsletter', is_newsletter)
			if (this.newsletterDate !== '') {
				formData.append('newsletter_send_date', this.dateEndPublish)
			}
			formData.append('title', this.title)
			formData.append('content', this.content)
			formData.append('banner_color', this.banner_color)
			formData.append('is_pin_as_top_post', this.is_pin_as_top_post)
			formData.append('file', this.fileList[0].raw)
			if (this.dateEndPublish && status !== 'Published') {
				formData.append('schedule_end_date', this.dateEndPublish)
				formData.append('visibility', 'Draft')
			} else {
				formData.append('visibility', status)
			}
			if (this.blogId !== '') {
				formData.append('id', this.blogId)
				this.$store.dispatch('portal/updateBlogPost', formData)
					.then(res => {
						this.processing = false
						this.publishing = false
						status === 'save' ? this.processing = false : this.publishing = false
						this.$services.helpers.notification(this.$t('blogUpdatedSuccess'), 'success', this)
					})
					.catch(err => {
						this.processing = false
						this.publishing = false
						status === 'save' ? this.processing = false : this.publishing = false
						window.Bus.$emit('output-error', err)
						// if (err.response.data === 400) {
						// 	this.$services.helpers.notification(err.response.data.error, 'error', this)
						// }
						// if (err.response.status === 401) {
						// 	this.$store.commit('auth/CLEAR_AUTH_USER', null)
						// 	window.Bus.$emit('sign-out')
						// }
					})
			} else {
				this.$store.dispatch('portal/addBlogPost', formData)
					.then(res => {
						this.$services.helpers.notification(this.$t('blogAddedSuccess'), 'success', this)
						this.blogId = res.data.blog_post_id
						this.processing = false
						this.publishing = false
						// this.clearBlogPost()
					})
					.catch(err => {
						this.processing = false
						this.publishing = false
						window.Bus.$emit('output-error', err)
						// if (err.response.data === 400) {
						// 	this.$services.helpers.notification(err.response.data.error, 'error', this)
						// }
					})
			}

		},
		getBase64File(file) {
			return new Promise((resolve, reject) => {
				var reader = new FileReader()
				reader.onload = e => {
					window.localStorage.setItem('__blogPreviewImageP__', e.target.result)
					resolve(e.target.result)
				}
				reader.readAsDataURL(file)
			})
		},
		getPreviewImage(file) {
			if (file !== '') {
				this.getBase64File(file)
					.then(res => {
						return res
					}).catch(re => {
					return ''
				})
			} else {
				return ''
			}
		},
		getArticles() {
			this.$store.dispatch('portal/getArticles', {url: `?limit=50`})
				.then(res => {
				}).catch((err) => {
				try {
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_USER', null)
						window.Bus.$emit('sign-out')
					}
				} catch (e) {
				}
			})
		},
		filterArticles(search) {
			let url = `?limit=50`
			if (search !== '') {
				url += `&search=${search}`
			}
			this.$store.dispatch('portal/getArticles', {url: url})
				.then(res => {
				}).catch((err) => {
				try {
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_USER', null)
						window.Bus.$emit('sign-out')
					}
				} catch (e) {
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.custom-btn-pay {
	background-color: #F167CA !important;
	border-color: #F167CA !important;
	font-weight: 800;
}

.custom-card-section {
	box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
	border-width: 0;
	transition: all .2s;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(26, 54, 126, 0.125);
}

.custom-save-btn {
	background-color: #2CDCA7 !important;
	border-color: #2CDCA7 !important;
	font-weight: 800;
}

.override-form-field {
	border: 0;
	border-bottom: 2px solid rgb(64, 138, 235);
	box-shadow: none;
	background-color: #fff !important;
	border-radius: unset !important;
}

.col-form-label {
	padding-top: 1rem !important;
	// font-size: 14px !important;
}

.form-group {
	margin-bottom: 0.4rem !important;
}

.form-input {
	font-size: 0.8rem !important;
	padding: 0.2rem 0.75rem !important;
}

.col-form-label {
	padding-bottom: unset !important;
	padding-top: 0.3rem !important;
}

.app-main .app-main__inner {
	padding-top: 20px !important;
}

.table th {
	// padding: 0.1rem 0.55rem !important;
	color: #000000 !important;
}

.form-control {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem .75rem !important;
}

.form-control {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem .75rem !important;
}

.form-select {
	border: 1px solid #DFE0EB !important;
	height: unset !important;
	padding: .175rem 1.5rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.btn-border-all {
	border: 1px solid $theme-secondary-color !important;
	color: $theme-secondary-color !important;
}

.table-striped tbody:nth-of-type(2n+1) {
	background-color: rgba(0, 0, 0, 0.03);
}

.c-border {
	border-width: 1px !important;
}

.custom-file-upload {
	// border: 1px solid #ccc;
	color: white;
	border-radius: 5px;
	background-color: $theme-primary-color;
	display: inline-block;
	padding: 6px 12px;
	// cursor: pointer;
}

.el-input--suffix .el-input__inner {
	height: 28px !important;
}

.overflow-y-scroll {
	overflow-y: scroll !important;
}

.h-300 {
	height: 300px;
}

.article-icon {
	position: absolute;
	z-index: 9;
	top: 41px;
	left: 7px;
}

.blog-date-publish {
	text-align: right;
}

@media screen and (max-width: 640px) {
	.sm-mb-10 {
		margin-top: 25px !important;
	}
	//	.blog-date-publish {
	//	float: none !important;
	//	width: 100% !important;
	//}
}
</style>

